import React from "react";
import '../styles/semantic.min.css';
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import Foundation from "../components/foundation/foundation";
import PageHeader from "../components/utils/PageHeader";
import {Container, Grid} from "semantic-ui-react";
import DesktopContainer from "../container/DesktopContainer";
import MobileTabletContainer from "../container/MobileTabletContainer";
import styles from "../components/foundation/foundation.module.css";
import layout from "../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'Stiftung'}/>

    <MobileTabletContainer className={styles.mobile}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Stiftung'} subHeader={'Unser Leitbild - unser Anspruch - unsere Überzeugung\n'}/>
            <Foundation/>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer className={styles.desktop}>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Stiftung'} subHeader={'Unser Leitbild - unser Anspruch - unsere Überzeugung\n'}/>
            <Grid centered columns={2}>
                <Grid.Column width={11}>
                    <Foundation/>
                </Grid.Column>
                <Grid.Column width={4}/>
            </Grid>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>

</LayoutContainer>