import React from "react";
import {Container, Header, Menu, Rail, Segment} from "semantic-ui-react";
import {Link} from "@reach/router";
import styles from "./foundation.module.css";
import cx from 'classnames';
import DesktopContainer from "../../container/DesktopContainer";


const Foundation = () => (
    <Segment className={styles.segment}>
        <DesktopContainer>
            <Rail position='right' className={styles.rail}>
                <Segment raised className={styles.menuSegment}>
                    <Menu secondary vertical className={styles.menu}>
                        <Link to="/stiftung/">
                            <Menu.Item className={cx(styles.menuItem, styles.active)}>Stiftung</Menu.Item>
                        </Link>
                        <Link to="/stiftung/grundsaetze/">
                            <Menu.Item className={styles.menuItem}>Grundsätze</Menu.Item>
                        </Link>
                        <Link to="/stiftung/principles/">
                            <Menu.Item className={styles.menuItem}>Prinzipien</Menu.Item>
                        </Link>
                        <Link to="/stiftung/transparenz/">
                            <Menu.Item className={styles.menuItem}>Transparenz</Menu.Item>
                        </Link>
                        <Link to="/stiftung/anlagerichtlinien/">
                            <Menu.Item className={styles.menuItem}>Anlagerichtlinien</Menu.Item>
                        </Link>
                    </Menu>
                </Segment>
            </Rail>
        </DesktopContainer>

        <Container className={styles.container}>
            <p>Die Deutsche Stiftung Kinderdermatologie ist seit dem 16. Dezember 2010 eine rechtsfähige Stiftung des bürgerlichen Rechts mit Sitz in München.</p>
            <p>Die Mitarbeiter der Gremien und der Geschäftsstelle fühlen sich den nachfolgenden Leitbild verpflichtet und unterstützen dieses als wesentlichen Bestandteil ihrer täglichen Arbeit.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                1. Gemeinsam zum Ziel
            </Header>
            <p>Gemeinsam mit Freunden, Zeitspendern, Botschaftern, Spendern, Förderern und Sponsoren, ob als Privatperson oder Unternehmer, wollen wir den Wandel erreichen für eine bessere medizinische und organisatorische Versorgung für hautkranke Kinder und Jugendliche. Wir geben ihnen eine Stimme im Gesundheitssystem. Sie haben das gleiche Recht wie hautkranke Erwachsene.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                2. Unser Motto – ein Herz für hautkranke Kinder und Jugendliche
            </Header>
            <p>Wir haben ein Herz für hautkranke Kinder und Jugendliche. Wir stehen den kleinen und jungen Patienten in ihrer besonderen Lebenssituation zur Seite. Wir begegnen ihnen und deren Familien mit Liebe und Achtung, mit Geduld und Freundlichkeit. Wir begegnen jeden Menschen mit Würde. Kein Mensch muss sich seine Würde verdienen, niemals kann er diese verlieren.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                3. Verantwortung
            </Header>
            <p>Alle Mitarbeiter der Stiftung bilden eine Dienstgemeinschaft in der jeder, die ihm eigene Rolle und Aufgabe mit hohem qualitativen Anspruch zum Wohle hautkranker Kinder und Jugendliche wahrnimmt.</p>
            <p>Aufrichtigkeit, Offenheit, gegenseitige Wertschätzung, Verständnis und Toleranz, aber auch Verantwortung, Einsatzbereitschaft und Integrationsfähigkeit sind die Basis unserer Zusammenarbeit.</p>
            <p>Alle Mitarbeiter verpflichten sich dem vertraulichen Umgang mit Daten der Hilfesuchenden, der Antragsteller, Spender, Sponsoren, Förderer und Mitarbeiter. Deren Zufriedenheit ist Maßstab unserer täglichen Arbeit.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                4. Leistung
            </Header>
            <p>Das besondere caritative Leistungsprofil unserer Stiftung stellen wir klar heraus. Als Stiftung erfüllen wir wesentliche Zukunftsaufgaben für hautkranke Kinder und Jugendliche mit gemeinnützigen und mildtätigen Zwecken entsprechend unserer Satzung und als Treuhänder gemäß dem Willen der Stifterin Frau Dr. Nicole Inselkammer.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                5. Wirtschaftlichkeit und Sparsamkeit
            </Header>
            <p>Wir handeln uneigennützig. Aus dem Selbstverständnis des Stiftungswesens fühlen wir uns verpflichtet, mit hoher Verantwortung wirtschaftlich und sparsam zu verfahren für eine nachhaltige Weiterentwicklung. Der Erfolg der Stiftung soll uns die Möglichkeit geben, unsere Förderangebote zu erweitern und zu vertiefen und zu verbessern. Wir verpflichten uns durch unser tägliches Handeln, den nachhaltigen Erfolg der Stiftung zu sichern und zu vergrößern.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                6. Transparenz
            </Header>
            <p>Wir vermeiden Interessenkonflikte. Die Deutsche Stiftung Kinderdermatologie fühlt sich verpflichtet, die Öffentlichkeit einfach, transparent und klar über ihre Struktur, ihr Engagement und die Mittelherkunft sowie ihre Verwendung zu informieren.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                7. Grundsätze guter Stiftungspraxis und „Principles of Good Practice“
            </Header>
            <p>Die Deutsche Stiftung Kinderdermatologie orientiert sich an den „Grundsätzen guter Stiftungspraxis“, die von dem Bundesverband Deutscher Stiftungen erstellt wurden und an den „Principles of Good Practice“ des European Foundation Centers.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                8. Evaluation - messbare Förderung
            </Header>
            <p>Die Deutsche Stiftung Kinderdermatologie evaluiert ihre Aktivitäten und Projekte regelmäßig im Hinblick auf die Zielerreichung innerhalb ihres Engagements. Durch ständige Überprüfung des Einsatzes der Fördermittel mit dem Berichtwesen und des werteorientierten Jahresberichtes nach den Kriterien des „Social Reporting Standard“ (SRS) unter Berücksichtigung von aktuellen Versorgungsstudien möchten wir den Spendern, Förderer und Sponsoren und der Öffentlichkeit klar nachweisen, wie nah wir unserem gemeinsamen Ziel gekommen sind.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                9. Verantwortungsvolle Anlagepolitik
            </Header>
            <p>Die Deutsche Stiftung Kinderdermatologie verpflichtet sich mit den Stiftungsgeldern verantwortungsvoll umzugehen. Sie hat dazu eine Leitlinie zur Verwaltung des Stiftungsvermögens erstellt. Die Verwaltung des Vermögens soll dem Gedanken eines nachhaltigen, sozialen und umweltverträglichen Wirtschaftens entsprechen. Ziel ist es, das Stiftungsvermögen langfristig zu erhalten und mittel- bis langfristig attraktive Renditen entsprechend dem Grundgedanken zu erwirtschaften.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                10. Qualität
            </Header>
            <p>Um die Qualität unserer Leistungen als Stiftung ständig zu verbessern, betreiben wir ein aktives Qualitätsmanagement. Wir überprüfen und verbessern die Qualität unserer Arbeit in einem fortlaufenden Prozess um den wachsenden Anforderungen an Qualität, Zuverlässigkeit, Notwendigkeit, Innovation und Service gerecht zu werden. Dies geschieht auf der Grundlage unserer Werteorientierung. Alle Schritte der relevanten Prozesse werden vollständig, präzise, nachvollziehbar und kontrollierbar dokumentiert. Die Prozesse sind an den Bedürfnissen der Hilfesuchenden und Antragssteller, Förderer, Spender und Sponsoren orientiert. Die Mitarbeiter kennen die Abläufe und arbeiten aktiv an der kontinuierlichen Verbesserung mit.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                11. Wissen, Kooperationen und Erfahrungsaustausch
            </Header>
            <p>Wissen ist ein wesentliches Gut unserer Stiftung. Wir fördern unsere Mitarbeiter, Wissen und Kompetenzen aufzubauen, auszuweiten und zu aktualisieren. Wir vernetzen unser Wissen untereinander, um voneinander zu lernen und um die Entwicklung und Veränderung der Abläufe, Strukturen und Qualität konsequent zu verbessern.</p>
            <p>Die Deutsche Stiftung Kinderdermatologie legt Wert auf die interdisziplinäre Zusammenarbeit sowohl innerhalb der Stiftung als auch mit externen Partnern. Dies folgt der Überzeugung, dass durch die Bündelung von Kompetenzen gemeinsam ganzheitlichere und nachhaltigere Lösungen für hautkranke Kinder und Jugendliche gefunden und die Wirkungen verstärkt werden können.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                12. Umweltorientierung
            </Header>
            <p> Wir wollen die bei unserer Arbeit verursachten Belastungen der Umwelt so gering wie möglich halten. Mit Wasser und Energie gehen wir sparsam um und legen Wert auf Müllvermeidung und Wiederverwertung.</p>
        </Container>

    </Segment>
);

export default Foundation;